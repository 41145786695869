import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { IconArrowRight, IconChevronLeft, IconDownload } from '@tabler/icons-react'

import Button from '@src/components/Button'

import { blocks } from '../../helpers/constants'
import { scrollToTop } from '@src/InvoiceGenerator/helpers/utils'
import { Block, InvoiceProps, Form } from '@src/InvoiceGenerator/helpers/types'

import cx from 'clsx'
import styles from '../../app.module.css'

const Builder: React.FC<InvoiceProps> = ({ isLoading, setSubmitAction }) => {
  const { t } = useTranslation('invoice', { keyPrefix: 'common' })
  const {
    values: { form_index },
    setFieldValue,
    validateForm,
  } = useFormikContext<Form>()

  const CurrentForm = Object.values(blocks)[form_index]

  const isFirst = form_index === 0
  const isLast = Object.values(blocks).length - 1 === form_index
  const isSubmitPage = form_index === 5

  return (
    <div className="w-full md:w-2/5 flex flex-col justify-between pl-14 pr-10 py-10 md:overflow-scroll ">
      <div>
        <header className="pb-8">
          <a className="text-brand_primary" rel="noreferrer" target="_blank" href="https://approveit.today/">
            <img className={styles.companyLogo} src="/logo.svg" alt="Approveit invoice generator" />
          </a>
          <h1 className={styles.companyLogoCaption}>{t('heading')}</h1>
        </header>
        <CurrentForm {...(form_index === Block.summary ? { isLoading, setSubmitAction } : {})} />
      </div>
      <div className={cx('flex flex-wrap items-center', isLast ? 'self-start' : 'self-end')}>
        <div className="flex items-center py-2">
          {!isFirst && (
            <Button
              className="mr-3 py-2 order-1"
              onClick={() => setFieldValue('form_index', form_index - 1)}
              color="white"
              buttonType="button"
            >
              <IconChevronLeft width={16} className="mr-1" />
              {isLast ? t('returnToInvoice') : t('back')}
            </Button>
          )}
        </div>
        {!isLast && (
          <Button
            className="mr-3 py-2"
            onClick={async () => {
              if (form_index === Block.payment) {
                await validateForm()
                if (setSubmitAction) setSubmitAction('submit')
              }
              setFieldValue('form_index', form_index + 1)
            }}
            buttonType="button"
          >
            {t('next')} <IconArrowRight className="h-4 text-red" color="white" />
          </Button>
        )}
        {!isSubmitPage && (
          <Button
            onClick={async () => {
              await validateForm()
              if (setSubmitAction) setSubmitAction('submit')
              setFieldValue('form_index', 5)
              scrollToTop()
            }}
            type="contained"
            buttonType="button"
            className="w-8 h-8 !bg-white px-0 items-center justify-center min-w-0 text-black md:self-end"
            tooltip={t('downloadTooltip')}
          >
            <IconDownload className="h-4 w-4 rounded-full" />
          </Button>
        )}
      </div>
    </div>
  )
}

export default Builder
