import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { IconDownload, IconRocket, IconSparkles } from '@tabler/icons-react'

import Button from '@src/components/Button'

import logo from '../../../assets/complete_logo.svg'
import { Form } from '@src/InvoiceGenerator/helpers/types'

interface SummaryProps {
  isLoading: boolean
  setSubmitAction: (submitAction: string) => void
}

const Summary: React.FC<SummaryProps> = ({ isLoading, setSubmitAction }) => {
  const { t } = useTranslation('invoice', { keyPrefix: 'summary' })
  const {
    setFieldValue,
    errors,
    errors: { number, due_date, vendor_details, customer_details, line_items, currency, payment_details_crypto },
  } = useFormikContext<Form>()

  return (
    <>
      <img className="w-2/3 mt-24 mx-auto" src={logo} alt="approveit-invoice-logo" />
      {Object.keys(errors).length > 0 ? (
        <>
          <h3 className="mb-8  text-2xl">{t('dataMissingHeading')}</h3>
          {(number || due_date) && <p className="mb-1 text-red-500">{t('termsCaption')}</p>}
          {vendor_details && <p className="mb-1 text-red-500">{t('companyCaption')}</p>}
          {customer_details && <p className="mb-1 text-red-500">{t('clientCaption')}</p>}
          {(currency?.value || line_items) && <p className="mb-1 text-red-500">{t('detailsCaption')}</p>}
          {(errors?.payment_details_fiat?.account_number ||
            errors?.payment_details_fiat?.bank_name ||
            errors?.payment_details_fiat?.swift ||
            payment_details_crypto) && <p className="mb-3 text-red-500">{t('paymentMethodCaption')}</p>}
        </>
      ) : (
        <>
          <h2 className="mb-8 text-3xl text-center text-slate-950 font-medium ">{t('summaryHeading')}</h2>
          <div className="grid grid-cols-2 gap-2 grid-rows-4">
            <Button
              type="default"
              buttonType="submit"
              onClick={() => setSubmitAction('download')}
              className="!h-14 text-xs lg:text-base"
              data-tip="Download PDF"
              loading={isLoading}
            >
              <IconDownload className="h-4 w-4" />
              {t('download')}
            </Button>
            <Button
              type="outlined"
              buttonType="submit"
              onClick={() => setSubmitAction('send')}
              className="!h-14 text-xs lg:text-base"
              loading={isLoading}
            >
              <IconRocket className="w-4 h-4" />
              {t('sendToClient')}
            </Button>
            <Button
              type="outlined"
              buttonType="button"
              className="!h-14 text-xs md:text-base col-span-2"
              onClick={() => setFieldValue('form_index', 0)}
              loading={isLoading}
              data-amplitude="new_invoice_from_generator"
            >
              <IconSparkles className="w-5 h-5" />
              {t('createNew')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default Summary
