import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { IconSquareRoundedPlus } from '@tabler/icons-react'
import { TrashIcon } from '@heroicons/react/solid'

import CurrencySelector from '@src/components/CurrencySelector'
import Button from '@src/components/Button'

import { inputsData } from '@src/InvoiceGenerator/helpers/constants'
import cx from 'clsx'
import { Form, LineItem } from '@src/InvoiceGenerator/helpers/types'

const Details = () => {
  const { t } = useTranslation('invoice', { keyPrefix: 'invoiceDetails' })
  const {
    values: { line_items, note, currency },
    setFieldValue,
    handleChange,
    errors,
  } = useFormikContext<Form>()

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (['-', 'e'].includes(e.key)) {
      e.preventDefault()
    }
  }, [])

  const changeLineItems = (value: string, i: number, type: string) => {
    const items = {
      ...line_items[i],
      [type]: value,
    }
    const subtotal = +(+items.price * +items.quantity).toFixed(2)
    items.total = +((subtotal * +items.tax) / 100 + subtotal).toFixed(2)
    setFieldValue(`line_items[${i}]`, items)
  }

  return (
    <>
      <h2 className="mb-8 text-lg font-medium">{t('invoiceDetailsCaption')}</h2>
      <CurrencySelector
        label={t('currencyLabel')}
        className="mb-6 py-0.5"
        onChange={(value) => setFieldValue('currency', value)}
        value={currency?.value}
        name="currency"
        hideDefaultLabel
        errorMessage={errors.currency?.value}
      />
      <div className="mb-12">
        <h3 className="mb-8 text-lg font-medium">{t('itemsCaption')}</h3>
        <div>
          {line_items?.map((item, i) => {
            const error = errors.line_items?.[i] as LineItem | undefined
            return (
              <div className="mb-3 flex justify-between items-center" key={i}>
                <Button
                  className="p-0"
                  type="contained"
                  buttonType="button"
                  onClick={() =>
                    setFieldValue(
                      'line_items',
                      line_items.filter((item, idx) => idx !== i),
                    )
                  }
                >
                  <TrashIcon className="w-4 h-4" />
                </Button>
                <div className="w-full flex justify-between gap-2 ml-3 py-3 border-b">
                  <input
                    className={cx(error?.item && 'placeholder-red-500 text-red-500', 'w-2/4 outline-none')}
                    name="item"
                    type="text"
                    value={item.item ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeLineItems(e.target.value, i, 'item')}
                    placeholder={t('itemsPlaceholder')}
                    autoComplete="off"
                  />
                  <div className="flex gap-1">
                    <input
                      className={cx(error?.quantity && 'placeholder-red-500 text-red-500', 'w-1/3 outline-none')}
                      name="quantity"
                      type="number"
                      value={item.quantity ?? ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        changeLineItems(e.target.value, i, 'quantity')
                      }
                      onKeyDown={onKeyDown}
                      placeholder={t('quantityPlaceholder')}
                      autoComplete="off"
                    />
                    <input
                      className={cx(error?.price && ' placeholder-red-500  text-red-500', 'w-2/3 outline-none')}
                      name="price"
                      type="number"
                      value={item.price ?? ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeLineItems(e.target.value, i, 'price')}
                      onKeyDown={onKeyDown}
                      placeholder={t('pricePlaceholder')}
                      autoComplete="off"
                    />
                    <p className="flex gap-2">
                      <input
                        className={cx(error?.tax && 'placeholder-red-500  text-red-500', 'w-7 outline-none')}
                        name="tax"
                        type="number"
                        value={item.tax ?? 0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeLineItems(e.target.value, i, 'tax')}
                        onKeyDown={onKeyDown}
                        placeholder={t('taxPlaceholder')}
                        autoComplete="off"
                      />
                      <span className="text-gray-400">(%)</span>
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
          <Button
            className="p-0"
            type="contained"
            buttonType="button"
            onClick={() => setFieldValue('line_items', [...line_items, inputsData.line_items_default])}
          >
            <IconSquareRoundedPlus className="h-5" />
            {t('addItemCaption')}
          </Button>
        </div>
      </div>
      <h3 className="mb-2 text-lg font-medium">{t('noteCaption')}</h3>
      {errors?.note && <p className="mb-6 text-red-500 text-sm">{errors?.note}</p>}
      <textarea
        className="w-full outline-none border-b"
        name="note"
        value={note ?? undefined}
        onChange={handleChange}
        placeholder="Add a note"
        autoComplete="off"
      />
    </>
  )
}

export default Details
