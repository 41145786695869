import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

import { Input } from '@src/components/Form/Input'

import { popperModifiers } from '@src/helpers/constants'
import { Form } from '@src/InvoiceGenerator/helpers/types'

const Terms = () => {
  const { t } = useTranslation('invoice', { keyPrefix: 'terms' })
  const {
    values: { number, issue_date, due_date },
    handleChange,
    setFieldValue,
    errors,
  } = useFormikContext<Form>()

  return (
    <>
      <h2 className="mb-8 text-lg font-medium">{t('invoiceTermsCaption')}</h2>
      <Input
        label={t('invoiceNumberLabel')}
        classes={{ label: 'font-medium' }}
        name="number"
        value={number ?? ''}
        onChange={handleChange}
        required={!!errors.number}
        autoComplete="off"
        errorMessage={errors.number}
      />
      <div className="grid mb-1">
        <DatePicker
          customInput={<Input label={t('issueDateLabel')} classes={{ label: 'font-medium' }} autoComplete="off" />}
          showPopperArrow
          selected={issue_date}
          dateFormat="yyyy-MM-dd"
          required={false}
          popperModifiers={popperModifiers}
          onChange={(value: Date) => setFieldValue('issue_date', value)}
        />
      </div>
      <div className="grid mb-1">
        <DatePicker
          customInput={
            <Input
              label={t('dueDateLabel')}
              classes={{ label: 'font-medium' }}
              autoComplete="off"
              errorMessage={errors.due_date as string}
            />
          }
          showPopperArrow
          selected={due_date}
          dateFormat="yyyy-MM-dd"
          required={!!errors.due_date}
          popperModifiers={popperModifiers}
          onChange={(value: Date) => setFieldValue('due_date', value)}
        />
      </div>
    </>
  )
}

export default Terms
