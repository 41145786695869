import React from 'react'

import { sections } from '../../helpers/constants'

import styles from '../../app.module.css'
import cx from 'clsx'

const Invoice = () => {
  return (
    <div className="w-full md:p-3 flex flex-col justify-center items-center bg-[#e8f4ff]">
      <div className={cx('my-6 md:w-4/6 md:order-1 max-w-2xl bg-white rounded-base', styles.invoiceSheet)}>
        <div className={cx('m-4 border border-gray-300 rounded-lg', styles.invoice)}>
          <div className="h-[90vh] grid grid-cols-2 grid-rows-[auto_auto_1fr_auto]">
            {Object.values(sections).map((section, index) => (
              <React.Fragment key={index}>{section}</React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invoice
