import React from 'react'
import i18n from '@src/i18n'

import { Block, InvoiceProps } from './types'

import {
  Terms,
  Company,
  Details,
  Payment,
  Summary,
  InvoiceTerms,
  InvoiceCompany,
  InvoiceDetails,
  InvoicePayment,
} from '../index'

export const inputsData = {
  company: [
    { name: 'email', label: i18n.t('invoice:companyDetails.emailLabel'), type: 'email' },
    { name: 'company_name', label: i18n.t('invoice:companyDetails.companyNameLabel') },
    { name: 'address', label: i18n.t('invoice:companyDetails.addressLabel') },
    { name: 'city', label: i18n.t('invoice:companyDetails.cityLabel') },
    { name: 'state', label: i18n.t('invoice:companyDetails.stateLabel') },
    { name: 'zip', label: i18n.t('invoice:companyDetails.zipLabel') },
    { name: 'country', label: i18n.t('invoice:companyDetails.countryLabel') },
    { name: 'tax_id', label: i18n.t('invoice:companyDetails.taxIdLabel') },
  ],
  fiat: [
    { name: 'bank_name', label: i18n.t('invoice:paymentMethod.bankNamePlaceholder') },
    { name: 'account_number', label: i18n.t('invoice:paymentMethod.accountNumberPlaceholder') },
    { name: 'routing_number', label: i18n.t('invoice:paymentMethod.routingNumberPlaceholder') },
    { name: 'swift', label: i18n.t('invoice:paymentMethod.swiftPlaceholder') },
  ],
  line_items_default: {
    tax: '',
    item: '',
    price: '',
    total: '',
    quantity: '',
  },
}

export const blocks = {
  [Block.terms]: () => <Terms />,
  [Block.vendor]: () => <Company isVendor />,
  [Block.client]: () => <Company />,
  [Block.details]: () => <Details />,
  [Block.payment]: () => <Payment />,
  [Block.summary]: ({ isLoading, setSubmitAction }: InvoiceProps) => (
    <Summary isLoading={isLoading!} setSubmitAction={setSubmitAction!} />
  ),
}

export const sections = {
  [Block.terms]: <InvoiceTerms />,
  [Block.vendor]: <InvoiceCompany isVendor />,
  [Block.client]: <InvoiceCompany />,
  [Block.details]: <InvoiceDetails />,
  [Block.payment]: <InvoicePayment />,
}
