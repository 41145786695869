export enum Block {
  terms,
  vendor,
  client,
  details,
  payment,
  summary,
}

export interface InvoiceProps {
  isLoading?: boolean
  setSubmitAction?: (action: string) => void
}

export const enum PaymentMethod {
  fiat = 'fiat',
  crypto = 'crypto',
}

export const paymentTypeOptions = [
  {
    key: PaymentMethod.fiat,
    label: 'Fiat',
  },
  {
    key: PaymentMethod.crypto,
    label: 'Crypto',
  },
]

export interface PaymentDetailsFiat {
  bank_name: string
  account_number: string
  routing_number: string
  swift: string
}

export interface PaymentDetailsCrypto {
  crypto_address: string
  crypto_currency: string
}

export interface CompanyDetails {
  email: string
  company_name: string
  address: string
  city: string
  state: string
  zip: string
  country: string
  tax_id: string
}

export interface SkeletonProps {
  isActive: boolean
  isCircle?: boolean
  isCompany?: boolean
  children: React.ReactNode
}

export interface SectionWrapperProps {
  className: string
  setIndex: () => void
  isSectionSelected: boolean
  isSectionError?: boolean
  hoverCaption: string
  children: React.ReactNode
}

export interface LineItem {
  item: string
  price: string
  tax: string
  total: number
  quantity: string
}

export interface CompanyProps {
  isVendor?: boolean
}

export interface Form {
  form_index: number
  issue_date: Date
  due_date: Date
  number: string
  vendor_details: CompanyDetails
  customer_details: CompanyDetails
  line_items: LineItem[]
  currency: { value: string; label: string; flag: string }
  payment_method: PaymentMethod
  payment_details_fiat: PaymentDetailsFiat
  payment_details_crypto: PaymentDetailsCrypto
  note: string | null
  total: number
  subtotal: number
}

export interface PreparedLineItem {
  item: string
  price: number
  tax: number
  total: number
  quantity: string
}

export interface PreparedForm {
  issue_date: string
  due_date: string
  number: string
  vendor_details: CompanyDetails
  customer_details: CompanyDetails
  line_items: PreparedLineItem[]
  payment_details: PaymentDetailsFiat | PaymentDetailsCrypto
  note: string | null
  total: number | string
  subtotal: number | string
  send_to_customer: boolean
}
