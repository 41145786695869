import React from 'react'
import InvoiceGeneratorApp from './InvoiceGenerator/App'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Toast from '@components/Toast'

import './i18n'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

const InvoiceGeneratorAppCmp = (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Toast />
      <InvoiceGeneratorApp />
    </QueryClientProvider>
  </React.StrictMode>
)

const root = createRoot(document.getElementById('root')!)

root.render(InvoiceGeneratorAppCmp)
