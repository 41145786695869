import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Input } from '@src/components/Form/Input'

import { inputsData } from '@src/InvoiceGenerator/helpers/constants'
import { Form, CompanyDetails } from '@src/InvoiceGenerator/helpers/types'

const Company = ({ isVendor }: { isVendor?: boolean }) => {
  const { t } = useTranslation('invoice', { keyPrefix: 'companyDetails' })
  const {
    values: { vendor_details, customer_details },
    setFieldValue,
    errors,
  } = useFormikContext<Form>()

  return (
    <>
      <h2 className="mb-8 text-lg font-medium">{isVendor ? `${t('companyHeading')}` : `${t('clientHeading')}`}</h2>
      {inputsData.company.map(({ name, label, type }) => {
        const company = isVendor ? vendor_details : customer_details
        const error = isVendor ? errors.vendor_details : errors.customer_details
        const isEmail = type === 'email'
        return (
          <React.Fragment key={name}>
            <Input
              label={label}
              classes={{ label: 'font-medium' }}
              name={name}
              value={company[name as keyof CompanyDetails] ?? ''}
              placeholder={isEmail ? 'e.g. info@acme.inc' : undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(isVendor ? `vendor_details[${name}]` : `customer_details[${name}]`, e.target.value)
              }
              required={isEmail && !!error}
              autoComplete="off"
              errorMessage={isEmail ? error?.email : error?.[name as keyof CompanyDetails]}
            />
            {isEmail && (
              <>
                <p className="mb-8 text-sm text-gray-500">{t('emailCaption')}</p>
                <h3 className="mb-6 text-lg font-medium">{t('BillingDetailsCaption')}</h3>
              </>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Company
