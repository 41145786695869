import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'

import Builder from '@src/InvoiceGenerator/components/Builder'
import Invoice from '@src/InvoiceGenerator/components/Invoice'

import { initialData, prepareValues, Schema } from './helpers/utils'
import useInvoiceDownload from './hooks/useInvoiceDownload'
import useAmplitude, { sendEvent } from '@helpers/hooks/useAmplitude'

const PATH = `/api/accounting/invoice-generator`

const InvoiceGeneratorApp = () => {
  const [submitAction, setSubmitAction] = useState('')
  const { isLoading, download } = useInvoiceDownload()
  useAmplitude()

  useEffect(() => {
    sendEvent('page_view_invoice_generator', {
      source: 'invoice_generator',
    })
  }, [])

  return (
    <Formik
      initialValues={initialData}
      validationSchema={Schema}
      validateOnChange={!!submitAction}
      onSubmit={(values) => download(prepareValues(values, submitAction), PATH, submitAction === 'download')}
    >
      <Form className="flex flex-col h-screen md:flex-row">
        <Builder isLoading={isLoading} setSubmitAction={setSubmitAction} />
        <Invoice />
      </Form>
    </Formik>
  )
}

export default InvoiceGeneratorApp
