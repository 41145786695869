import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { format } from 'date-fns'

import SectionWrapper from '../SectionWrapper'

import { Form } from '@src/InvoiceGenerator/helpers/types'

const InvoiceTerms: React.FC = () => {
  const { t } = useTranslation('invoice', { keyPrefix: 'terms' })
  const {
    values: { form_index, number, issue_date, due_date },
    errors,
    setFieldValue,
  } = useFormikContext<Form>()

  return (
    <SectionWrapper
      className="grid grid-cols-2 col-span-2"
      setIndex={() => setFieldValue('form_index', 0)}
      isSectionSelected={form_index === 0}
      isSectionError={!!(errors.number || errors?.due_date)}
      hoverCaption={t('invoiceTermsCaption')}
    >
      <div className="h-[48px] pl-8 pt-3 pb-10 border-b">
        <h2 className="text-gray-400 text-xs">{t('invoiceNoCaption')}</h2>
        <p className="w-4/5 text-slate-950 text-sm font-bold truncate">{number}</p>
      </div>
      <div className="flex h-[48px] pt-3 pb-10 border-b">
        <div className="w-full">
          <h2 className="text-gray-400 text-xs">{t('invoiceIssueDateCaption')}</h2>
          <p className="w-4/5 text-slate-950 text-sm font-bold truncate">
            {issue_date && format(issue_date, 'yyyy/MM/dd')}
          </p>
        </div>
        <div className="w-full">
          <h2 className="text-gray-400 text-xs">{t('dueDateLabel')}</h2>
          <p className="w-4/5 text-slate-950 text-sm font-bold truncate">
            {due_date && format(due_date, 'yyyy/MM/dd')}
          </p>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default InvoiceTerms
