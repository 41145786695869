import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import SectionWrapper from '../SectionWrapper'
import Skeleton from '../Skeleton'

import cx from 'clsx'
import { Form, CompanyProps } from '@src/InvoiceGenerator/helpers/types'

const InvoiceCompany: React.FC<CompanyProps> = ({ isVendor }) => {
  const { t } = useTranslation('invoice', { keyPrefix: 'companyDetails' })
  const {
    values: { form_index, vendor_details, customer_details },
    setFieldValue,
    errors,
  } = useFormikContext<Form>()

  const companyDetails = isVendor ? vendor_details : customer_details

  const { company_name, email, address, city, state, zip, country, tax_id } = companyDetails

  return (
    <SectionWrapper
      className={cx('px-8 pt-4 pb-10 border-b word-break overflow-hidden', isVendor && 'border-r')}
      setIndex={() => setFieldValue('form_index', isVendor ? 1 : 2)}
      isSectionSelected={form_index === (isVendor ? 1 : 2)}
      isSectionError={isVendor ? !!errors.vendor_details : !!errors?.customer_details}
      hoverCaption={isVendor ? `${t('companyHeading')}` : `${t('clientHeading')}`}
    >
      <Skeleton isCompany isActive={Object.values(companyDetails).some((value) => value)}>
        <h2 className="text-gray-400 text-sm">{isVendor ? 'From' : 'To'}</h2>
        <h3 className="text-lg font-bold">{company_name}</h3>
        <p className="text-sm my-2">{email}</p>
        <p className="text-sm">{address}</p>
        <p className="text-sm">
          <span>{city}</span>
          {state && <span>, {state}</span>}
          {zip && <span>, {zip}</span>}
        </p>
        <p className="text-sm">{country}</p>
        {tax_id && (
          <p className="text-sm">
            {t('taxIdLabel')}: {tax_id}
          </p>
        )}
      </Skeleton>
    </SectionWrapper>
  )
}

export default InvoiceCompany
