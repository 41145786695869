import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import SectionWrapper from '../SectionWrapper'
import Skeleton from '../Skeleton'

import cx from 'clsx'
import { PaymentMethod, Form } from '@src/InvoiceGenerator/helpers/types'

const InvoicePayment: React.FC = () => {
  const { t } = useTranslation('invoice', { keyPrefix: 'paymentMethod' })
  const {
    values: {
      form_index,
      currency,
      payment_method,
      payment_details_fiat: { bank_name, account_number, routing_number, swift },
      payment_details_crypto: { crypto_currency, crypto_address },
    },
    setFieldValue,
    errors,
  } = useFormikContext<Form>()

  const isFiat = payment_method === PaymentMethod.fiat

  return (
    <SectionWrapper
      className="pl-8 py-6 grid grid-cols-2 col-span-2"
      setIndex={() => setFieldValue('form_index', 4)}
      isSectionSelected={form_index === 4}
      isSectionError={
        isFiat
          ? !!(
              errors?.payment_details_fiat?.account_number ||
              errors?.payment_details_fiat?.bank_name ||
              errors?.payment_details_fiat?.swift
            )
          : !!errors.payment_details_crypto
      }
      hoverCaption={t('paymentDetailsCaption')}
    >
      <div>
        <h2 className="mb-3 text-gray-400 text-sm">{t('invoicePaymentCaption')}</h2>
        <div className="mb-3 flex">
          {isFiat && (
            <Skeleton isActive={!!currency?.value} isCircle>
              <div className="w-[34px] h-[34px] mr-2 flex justify-center items-center border rounded-full">
                <div
                  className={cx(
                    '!bg-[length:22px_14px] !w-[22px] !h-3.5 flag flag-xs currency?.flag',
                    currency?.flag && `flag-country-${currency?.flag}`,
                  )}
                />
              </div>
            </Skeleton>
          )}
          <div className="w-3/5 grid gap-y-1">
            <Skeleton isActive={!!currency?.label || !!crypto_currency}>
              <p className="text-sm font-bold overflow-hidden">{isFiat ? currency?.label : crypto_currency}</p>
            </Skeleton>
            {isFiat && (
              <Skeleton isActive={!!currency?.value}>
                <p>{currency?.value}</p>
              </Skeleton>
            )}
          </div>
        </div>
        <a
          className="text-xs text-brand_primary font-medium"
          rel="noreferrer"
          target="_blank"
          href="https://approveit.today/"
        >
          {t('createdWithCaption')}
        </a>
      </div>
      {isFiat ? (
        <div>
          <div className="grid grid-cols-[fit-content(40%)_1fr] gap-x-2 gap-y-2 ">
            <p className="text-gray-400 text-xs">{t('bankNamePlaceholder')}</p>
            <Skeleton isActive={!!bank_name}>
              <p className="text-sm w-4/5 truncate">{bank_name}</p>
            </Skeleton>
            <p className="text-gray-400 text-xs">{t('accountNumberPlaceholder')}</p>
            <Skeleton isActive={!!account_number}>
              <p className="text-sm w-4/5 truncate">{account_number}</p>
            </Skeleton>
            <p className="text-gray-400 text-xs">{t('routingNumberPlaceholder')}</p>
            <Skeleton isActive={!!routing_number}>
              <p className="text-sm w-4/5 truncate">{routing_number}</p>
            </Skeleton>
            <p className="text-gray-400 text-xs">{t('swiftPlaceholder')}</p>
            <Skeleton isActive={!!swift}>
              <p className="text-sm w-4/5 truncate">{swift}</p>
            </Skeleton>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-[fit-content(40%)_1fr] gap-x-2 gap-y-2 grid-rows-[min-content] overflow-hidden">
          <p className="text-gray-400 text-xs">{t('cryptoCurrencyLabel')}</p>
          <Skeleton isActive={!!crypto_currency}>
            <p className="text-sm  w-4/5">{crypto_currency}</p>
          </Skeleton>
          <p className="text-gray-400 text-xs w-3/5">{t('cryptoAddressLabel')}</p>
          <Skeleton isActive={!!crypto_address}>
            <p className="text-sm  w-4/5 truncate">{crypto_address}</p>
          </Skeleton>
        </div>
      )}
    </SectionWrapper>
  )
}

export default InvoicePayment
