import React from 'react'

import cx from 'clsx'
import styles from '../../../app.module.css'
import { SkeletonProps } from '@src/InvoiceGenerator/helpers/types'

const Skeleton: React.FC<SkeletonProps> = ({ isCompany, isCircle, isActive, children }) => {
  if (isActive) {
    return <>{children}</>
  }

  if (isCompany) {
    return (
      <div role="status" className="w-3/5 max-w-sm animate-pulse mt-2.5">
        <div className={cx('w-full mb-2.5', styles.skeleton)} />
        <div className={cx('w-full mb-2.5', styles.skeleton)} />
        <div className={cx('w-4/5 mb-2.5', styles.skeleton)} />
        <div className={cx('w-3/5 mb-2.5', styles.skeleton)} />
        <div className={cx('w-2/5 ', styles.skeleton)} />
      </div>
    )
  }

  if (isCircle) {
    return (
      <div role="status" className="w-[34px] h-[34px] animate-pulse mr-2">
        <div className="h-[34px] bg-purple-100 rounded-full dark:bg-purple-200" />
      </div>
    )
  }

  return <div className={cx('w-4/5', styles.skeleton)} />
}

export default Skeleton
