import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import Switch from '@components/Switch'
import { Input } from '@src/components/Form/Input'

import { inputsData } from '@src/InvoiceGenerator/helpers/constants'
import { PaymentMethod, paymentTypeOptions, PaymentDetailsFiat, Form } from '@src/InvoiceGenerator/helpers/types'

const Payment = () => {
  const { t } = useTranslation('invoice', { keyPrefix: 'paymentMethod' })
  const {
    values: { payment_method, payment_details_fiat, payment_details_crypto },
    setFieldValue,
    errors,
  } = useFormikContext<Form>()

  return (
    <>
      <h2 className="mb-8 text-lg font-medium">{t('paymentMethodHeading')}</h2>
      <Switch
        className="w-full mb-6"
        classes={{ active: '!bg-purple-50  border border-brand_primary ' }}
        options={paymentTypeOptions}
        onSelect={(type: PaymentMethod) => setFieldValue('payment_method', type)}
        active={payment_method}
      />
      <>
        {payment_method === PaymentMethod.fiat ? (
          <>
            {inputsData.fiat.map(({ name, label }) => {
              const error = errors.payment_details_fiat?.[name as keyof PaymentDetailsFiat]
              return (
                <Input
                  key={name}
                  classes={{ label: 'font-medium' }}
                  label={label}
                  name={name}
                  value={payment_details_fiat[name as keyof PaymentDetailsFiat] ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(`payment_details_fiat[${name}]`, e.target.value)
                  }
                  required={!!error}
                  autoComplete="off"
                  errorMessage={error}
                />
              )
            })}
          </>
        ) : (
          <>
            <Input
              label={t('cryptoCurrencyLabel')}
              name="crypto_currency"
              classes={{ label: 'font-medium' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('payment_details_crypto', {
                  ...payment_details_crypto,
                  crypto_currency: e.target.value,
                })
              }
              value={payment_details_crypto.crypto_currency ?? ''}
              required={!!errors.payment_details_crypto?.crypto_currency}
              autoComplete="off"
              errorMessage={errors.payment_details_crypto?.crypto_currency}
            />
            <Input
              label={t('cryptoAddressLabel')}
              name="crypto_address"
              classes={{ label: 'font-medium' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue('payment_details_crypto', {
                  ...payment_details_crypto,
                  crypto_address: e.target.value,
                })
              }
              value={payment_details_crypto.crypto_address ?? ''}
              required={!!errors.payment_details_crypto?.crypto_address}
              autoComplete="off"
              errorMessage={errors.payment_details_crypto?.crypto_address}
            />
          </>
        )}
      </>
    </>
  )
}

export default Payment
