import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import SectionWrapper from '../SectionWrapper'
import Skeleton from '../Skeleton'

import { Form } from '@src/InvoiceGenerator/helpers/types'
import { formatNumber } from '@src/InvoiceGenerator/helpers/utils'

const InvoiceDetails: React.FC = () => {
  const { t } = useTranslation('invoice', { keyPrefix: 'invoiceDetails' })
  const {
    values: { form_index, line_items, currency, note },
    setFieldValue,
    errors,
  } = useFormikContext<Form>()

  const isSectionError = !!errors.currency?.value || !!errors.line_items

  return (
    <SectionWrapper
      className="grid grid-cols-2 auto-rows-max border-b overflow-auto col-span-2"
      setIndex={() => setFieldValue('form_index', 3)}
      isSectionSelected={form_index === 3}
      isSectionError={isSectionError}
      hoverCaption={t('invoiceDetailsCaption')}
    >
      <div className="pl-8 pt-6">
        <h2 className="text-gray-400 text-sm">{t('itemCaption')}</h2>
      </div>
      <div className="pl-8 pr-8 pt-6 grid grid-cols-[repeat(4,_1fr)] gap-1">
        <h2 className="text-gray-400 text-sm">{t('quantityPlaceholder')}</h2>
        <h2 className="text-gray-400 text-sm">{t('pricePlaceholder')}</h2>
        <h2 className="text-gray-400 text-sm">{t('taxPlaceholder')}</h2>
        <h2 className="text-gray-400 text-sm">{t('amountCaption')}</h2>
      </div>
      {line_items.map(({ item, quantity, price, tax, total }, idx) => {
        return (
          <React.Fragment key={idx}>
            <div className="ml-8 pt-3 py-3 border-b">
              <Skeleton isActive={!!item}>
                <h2 className="text-sm font-bold truncate">{item}</h2>
              </Skeleton>
            </div>
            <div className="mr-8 pl-8 py-3 grid grid-cols-[repeat(4,_1fr)] gap-1 border-b">
              <Skeleton isActive={!!quantity}>
                <h2 className="text-sm font-bold truncate">{formatNumber(+quantity)}</h2>
              </Skeleton>
              <Skeleton isActive={!!price}>
                <h2 className="text-sm font-bold truncate">{formatNumber(+price)}</h2>
              </Skeleton>
              <h2 className="text-sm font-bold truncate">
                {tax || '0'}
                <span>%</span>
              </h2>
              <Skeleton isActive={!!price}>
                {total && total > 0 && <h2 className="text-sm font-bold truncate">{formatNumber(total)}</h2>}
              </Skeleton>
            </div>
          </React.Fragment>
        )
      })}
      <div className="pl-8 pt-6">
        {note && (
          <>
            <h2 className="text-gray-400 text-sm">{t('noteCaption')}</h2>
            <h2 className="text-gray-400 text-sm font-bold truncate">{note}</h2>
          </>
        )}
      </div>
      <div className="pl-8 pr-8">
        <div className="flex justify-between items-center border-b">
          <h4 className="py-3 text-gray-400 text-sm">{t('subtotalCaption')}:</h4>
          <p className="px-2 truncate">
            {formatNumber(+line_items.reduce((acc, item) => acc + +item.price * +item.quantity, 0))}
          </p>
        </div>
        <div className="flex justify-between items-center">
          <h4 className="py-3 text-gray-400 text-sm">{`${t('totalCaption')}: (${currency?.value})`}</h4>
          <p className="px-2 text-lg font-bold truncate">
            {formatNumber(+line_items.reduce((acc, item) => acc + item.total, 0))}
          </p>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default InvoiceDetails
