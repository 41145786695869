import { useCallback, useState } from 'react'
import api from '@api/api'

import { requestErrorsHandler } from '@helpers/utils'
import { PreparedForm } from '../helpers/types'
import { sendEvent } from '@helpers/hooks/useAmplitude'

export const useInvoiceDownload = () => {
  const [isLoading, setIsLoading] = useState(false)

  const download = useCallback(async (data: PreparedForm, path: string, isDownloadPDF: boolean) => {
    try {
      setIsLoading(true)
      const fileRes = await api.post(path, data, {
        responseType: 'blob',
        headers: { 'Content-Type': 'application/json' },
      })

      if (data.send_to_customer) {
        sendEvent('send_invoice_from_generator', {
          source: 'invoice_generator',
        })
      }

      if (isDownloadPDF) {
        sendEvent('download_invoice_from_generator', {
          source: 'invoice_generator',
        })
        const nameFromResponse = fileRes.headers['content-disposition']?.split('filename=')?.[1]
        const name = nameFromResponse ? decodeURIComponent(nameFromResponse) : ''
        const href = URL.createObjectURL(new Blob([fileRes.data]))
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', name)
        link.click()
        URL.revokeObjectURL(href)
      }
    } catch (e) {
      requestErrorsHandler(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    isLoading,
    download,
  }
}

export default useInvoiceDownload
