import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import cx from 'clsx'
import styles from '../../../app.module.css'
import { scrollToTop } from '@src/InvoiceGenerator/helpers/utils'
import { SectionWrapperProps } from '@src/InvoiceGenerator/helpers/types'

const SectionWrapper: React.FC<SectionWrapperProps> = ({
  className,
  setIndex,
  isSectionSelected,
  isSectionError,
  hoverCaption,
  children,
}) => {
  const [isSectionHovered, setIsSectionHovered] = useState(false)

  return (
    <div
      className={twMerge(
        cx(
          'relative z-0 cursor-pointer',
          className,
          isSectionSelected && styles.selected,
          isSectionError && styles.error,
        ),
      )}
      onClick={() => {
        setIndex()
        scrollToTop()
      }}
      onMouseEnter={() => setIsSectionHovered(true)}
      onMouseLeave={() => setIsSectionHovered(false)}
    >
      {isSectionHovered && (
        <div className="absolute top-7 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
          <p className="w-28 leading-7 text-white text-xs text-center rounded-md bg-gray-900">{hoverCaption}</p>
        </div>
      )}
      {children}
    </div>
  )
}

export default SectionWrapper
