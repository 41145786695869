import * as Yup from 'yup'
import i18n from '@src/i18n'
import format from 'date-fns/format'
import { startOfMonth } from 'date-fns'

import { Form, Block, PaymentMethod } from '@src/InvoiceGenerator/helpers/types'

const MAX_LEN_32 = 32
const MAX_LEN_50 = 50
const MAX_LEN_100 = 100
const MAX_LEN_255 = 255

const CompanySchema = Yup.object().shape({
  email: Yup.string().email(i18n.t('invoice:validation.email')).required(i18n.t('invoice:validation.emailRequired')),
  company_name: Yup.string()
    .max(MAX_LEN_255, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_255 }))
    .required(i18n.t('invoice:validation.companyNameRequired')),
  address: Yup.string()
    .max(MAX_LEN_100, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_100 }))
    .nullable(),
  city: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  country: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  state: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  tax_id: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  zip: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
})

const PaymentFiatSchema = Yup.object().shape({
  bank_name: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  account_number: Yup.string()
    .when('$payment_method', {
      is: PaymentMethod.fiat,
      then: (schema) => schema.required(i18n.t('invoice:validation.accountNumberRequired')),
    })
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  routing_number: Yup.string()
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
  swift: Yup.string()
    .when('$payment_method', {
      is: PaymentMethod.fiat,
      then: (schema) => schema.required(i18n.t('invoice:validation.swiftRequired')),
    })
    .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
    .nullable(),
})

const PaymentCryptoSchema = Yup.object().shape({
  crypto_address: Yup.string().when('$payment_method', {
    is: PaymentMethod.crypto,
    then: (schema) => schema.required(i18n.t('invoice:validation.cryptoAddressRequired')),
  }),
  crypto_currency: Yup.string().when('$payment_method', {
    is: PaymentMethod.crypto,
    then: (schema) => schema.required(i18n.t('invoice:validation.cryptoCurrencyRequired')),
  }),
})

const LineItemsSchema = Yup.array(
  Yup.object().shape({
    item: Yup.string()
      .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
      .required(),
    price: Yup.string()
      .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
      .required(),
    quantity: Yup.string()
      .max(MAX_LEN_50, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_50 }))
      .required(),
  }),
)

export const Schema = Yup.object().shape({
  number: Yup.string()
    .required(i18n.t('invoice:validation.invoiceNumberRequired'))
    .max(MAX_LEN_32, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_32 })),
  issue_date: Yup.date().required(i18n.t('invoice:validation.issueDate')),
  due_date: Yup.date().test(
    'is-greater-than-due_date',
    i18n.t('invoice:validation.dueDate'),
    (value, { parent: { issue_date } }) => issue_date <= value!,
  ),
  vendor_details: CompanySchema,
  customer_details: CompanySchema,
  currency: Yup.object().shape({
    value: Yup.string().required(i18n.t('invoice:validation.currencyRequired')),
  }),
  payment_method: Yup.string().required(),
  payment_details_fiat: PaymentFiatSchema,
  payment_details_crypto: PaymentCryptoSchema,
  line_items: LineItemsSchema,
  note: Yup.string()
    .max(MAX_LEN_255, i18n.t('invoice:validation.maxLen', { len: MAX_LEN_255 }))
    .nullable(),
})

export const initialData = {
  form_index: Block.terms,
  issue_date: new Date(),
  due_date: new Date(),
  number: format(startOfMonth(new Date()), 'dd/MM/yyyy'),
  vendor_details: {
    email: '',
    company_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    tax_id: '',
  },
  customer_details: {
    email: '',
    company_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    tax_id: '',
  },
  line_items: [
    {
      tax: '',
      item: '',
      price: '',
      total: 0,
      quantity: '',
    },
  ],
  currency: { value: '', label: '', flag: '' },
  payment_method: PaymentMethod.fiat,
  payment_details_fiat: {
    bank_name: '',
    account_number: '',
    routing_number: '',
    swift: '',
  },
  payment_details_crypto: {
    crypto_address: '',
    crypto_currency: '',
  },
  note: null,
  total: 0,
  subtotal: 0,
}

export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: Number.isInteger(number) ? 0 : 2 }).format(number)
}

export const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

export const prepareValues = (values: Form, submitAction: string) => {
  const {
    number,
    vendor_details,
    customer_details,
    currency,
    payment_method,
    payment_details_fiat,
    payment_details_crypto,
    line_items,
    note,
  } = values

  const send_to_customer = submitAction === 'send'
  const total = line_items.reduce((acc, item) => acc + item.total, 0)
  const subtotal = line_items.reduce((acc, item) => acc + +item.price * +item.quantity, 0)

  return {
    number,
    issue_date: format(values.issue_date, 'yyyy-MM-dd'),
    due_date: format(values.issue_date, 'yyyy-MM-dd'),
    vendor_details,
    customer_details,
    line_items: line_items.map(({ tax, price, total }, i) => {
      return { ...line_items[i], tax: +tax * 100, price: +price * 100, total: +total * 100 }
    }),
    payment_details: {
      currency: currency.value,
      payment_method,
      ...(payment_method === PaymentMethod.fiat
        ? { ...payment_details_fiat }
        : { ...payment_details_crypto, payment_method }),
    },
    send_to_customer,
    note: note || null,
    total: send_to_customer ? total.toFixed(2).toString() : (total * 100).toFixed(2),
    subtotal: send_to_customer ? subtotal.toFixed(2).toString() : (subtotal * 100).toFixed(2),
  }
}
